import React, { useContext } from "react"
import { gql, useQuery } from "@apollo/client"
import { useAuth } from "../auth"
import rollbar from "app/lib/rollbar"
import { replaceSubdomain } from "app/utils"
import { useUniversalCtx } from "../universal-context"

const getPathnameWithoutCompanySlug = (url) => {
  // remove empty string and organization slug
  const [, , ...rest] = url.pathname.split("/")
  return "/" + rest.join("/")
}

const handleCurrentUser = (currentUser) => {
  ;[rollbar.setUser].forEach((fn) => fn(currentUser))
}

const Context = React.createContext()

export const QUERY = gql`
  query GetCurrentUser {
    originalCurrentUser {
      id
    }
    currentUser {
      id
      email
      firstName
      lastName
      fullName
      photoUrl
      admin
      permissions
      pendingInvitation
      lifeAccess
      elevateAppAccess
      featureFlagsString
      roleId
      role {
        id
        name
        displayName
      }
      userSettings {
        simplifiedInterface
        eventsReminderPushNotification
        eventsReminderEmail
        birthdayMessageEnabled
        hideBirthdayInDirectory
        weeklySummaryMessageEnabled
      }
      organization {
        id
        slug
        isChildless
      }
    }
  }
`

export const useCurrentUser = () => useContext(Context)

export const Consumer = Context.Consumer

export const userTypes = {
  COMMUNITY: "community",
  DEFAULT: "default",
  FAMILY_MEMBER: "family_member",
  GUEST: "guest",
}

function hasPermission(permission) {
  // transform ["user:read", "article:manage"] into { user: { read: true }, article: { manage: true } }
  const normalizedPermissions = {}
  for (const p of this.permissions) {
    const [entity, action] = p.split(":")
    if (!normalizedPermissions[entity]) normalizedPermissions[entity] = {}
    if (!normalizedPermissions[entity][action])
      normalizedPermissions[entity][action] = true
  }

  const [entity, action] = permission.split(":")
  // 'all' gives access to all entities and 'manage' allow all actions
  if (normalizedPermissions.all?.manage) {
    return true
  }
  if (normalizedPermissions.all?.read && action === "read") {
    return true
  }

  return !!normalizedPermissions[entity]?.[action]
}

const getUserType = (data) => {
  if (data.currentUser.id === "0") return userTypes.COMMUNITY
  // when we sign in on behalf of another user, originalCurrentUser will have the original user
  // that's how we are able to identify the family member users
  if (data.originalCurrentUser) return userTypes.FAMILY_MEMBER
  return userTypes.DEFAULT
}

const createGuestUser = () => ({
  fullName: "Guest",
  userType: userTypes.GUEST,
  isAuthenticated: false,
  roleId: 0,
  userSettings: {
    simplifiedInterface: false,
    eventsReminderPushNotification: false,
    eventsReminderEmail: false,
    birthdayMessageEnabled: false,
    hideBirthdayInDirectory: false,
    weeklySummaryMessageEnabled: false
  },
  isEndUser: false,
  permissions: [],
  featureFlags: {},
  hasPermission,
})

export const getUserData = (data) => {
  const userData = createGuestUser()
  if (data?.currentUser) {
    Object.assign(userData, data.currentUser)
    userData.userType = getUserType(data)
    userData.isAuthenticated = true
    userData.isEndUser = userData.userType === userTypes.DEFAULT
    userData.featureFlags = userData.featureFlagsString ? JSON.parse(userData.featureFlagsString) : {}
  }

  return userData
}

export const Provider = ({ children }) => {
  const auth = useAuth()
  const { url, redirect, isDiscover } = useUniversalCtx()

  const { data, loading } = useQuery(QUERY, {
    skip: !auth.isAuthenticated() || isDiscover,
  })

  let currentUser
  // avoid being redirected to login if user is not authenticated
  if (auth.getToken()) {
    if (loading) return null

    currentUser = getUserData(data)
  } else {
    currentUser = getUserData()
  }

  handleCurrentUser(currentUser)
  if (currentUser.isAuthenticated && typeof window !== "undefined" && window.StonlyWidget) {
    window.StonlyWidget('identify', currentUser.id, {
      'currentuser.userid': currentUser.id,
      'currentuser.firstname': currentUser.firstName,
      'currentuser.lastname': currentUser.lastName,
      'currentuser.email': currentUser.email
    });
  }

  // connect user
  if (currentUser.userType === userTypes.FAMILY_MEMBER) {
    if (!url.host.startsWith("connect")) {
      // connect users should access app from connect domain
      redirect(replaceSubdomain(url, "connect"))
      return null
    } else if (
      // connect users cannot see users or groups
      getPathnameWithoutCompanySlug(url).startsWith("/users") ||
      getPathnameWithoutCompanySlug(url).startsWith("/groups")
    ) {
      redirect("/")
      return null
    }
  }

  return <Context.Provider value={currentUser}>{children}</Context.Provider>
}
