

export const routesFilter = (route, independentLiving, staff) => {
    if (independentLiving || staff) return route.items; 
    switch (route.label) {
        case "Connect":
            return route.items.filter((item) => item.label === "Directory"); // Keep only "Directory"
        case "Resources":
            return route.items.filter((item) => item.label !== "Concierge"); // Remove "Concierge"
        default:
            return route.items; // Keep all items for other sections
    }
};